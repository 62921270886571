import React, { useState } from 'react';
import '../../pages/css/styles.css';
import AppCard from '../Cards/AppCard';
import amharicDic from "../../assets/logos/amahric-dic.png";
import bible from "../../assets/logos/bible.png";
import easton from "../../assets/logos/easton.png";
import kjv from "../../assets/logos/kjv.png";
import fynGeez from "../../assets/logos/fyngeeze.png";
import tools from "../../assets/logos/tools.png";
import advancedDic from "../../assets/logos/adv.png";
import amhImage from "../../assets/images/amharicbible.png";
import kjvImage from "../../assets/images/kjvbible.png";
import fynImage from "../../assets/images/fynkeyboard.png";
import advImage from "../../assets/images/advdic.png";
import amhDicImage from "../../assets/images/amhdic.png";
import eastonImage from "../../assets/images/eastonImage.png";
import toolsImage from "../../assets/images/toolsImage.png";
import MobileImageSrc from "../../assets/images/2.png";


function Carousel({ cards }) {





    const nextSlide = () => {

    }
    const prevSlide = () => {

    };



    const handleFynGeezeClick = () => {
        setHowtoImage(fynImage);
        setHowtoText("FYN Geeze App - Learn Geez alphabet and more.");

    };

    const handleBibleClick = () => {
        setHowtoImage(amhImage);
        setHowtoText("Amharic Bible - Read and explore the scriptures.");
    };

    const handleKJVClick = () => {
        setHowtoImage(kjvImage);
        setHowtoText("KJV Bible - Study the King James Version.");
    };

    const handleEastonClick = () => {
        setHowtoImage(eastonImage);
        setHowtoText("Easton Bible Dictionary - Explore biblical terms.");
    };

    const handleAmharicDicClick = () => {
        setHowtoImage(amhDicImage);
        setHowtoText("Amharic Dictionary - Learn Amharic words.");
    };

    const handleToolsClick = () => {
        setHowtoImage(toolsImage);
        setHowtoText("Amharic Dictionary - Learn Amharic words.");
    };
    const handleAdvancedClick = () => {
        setHowtoImage(advImage);
        setHowtoText("Amharic Dictionary - Learn Amharic words.");
    };


    const appData = [
        { action: handleFynGeezeClick, appName: " FynGeez Keyboard", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", logo: fynGeez },
        { action: handleBibleClick, appName: " Amharic Bible", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", logo: bible },
        { action: handleAmharicDicClick, appName: "Amharic Dictionary", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", logo: advancedDic },
        { action: handleToolsClick, appName: "Amharic Tools", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", logo: tools },
        { action: handleEastonClick, appName: "Easton Bible Dictionary", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", logo: easton }
    ];
    const [activeIndex, setActiveIndex] = useState(0);
    const [howtoImage, setHowtoImage] = useState(MobileImageSrc);
    const [howtoText, setHowtoText] = useState("Start conversation");
    return (
        <>
            <section id="howto">
                <div class="row">
                    <div class="col span_1_of_3 first-col">
                        <div class="first-item">
                            <div class="item-description">
                                <h4>Start conversation</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut
                                </p>
                            </div>
                            <div class="item-icon1">
                                <svg
                                    width="2.5rem"
                                    height="2.5rem"
                                    viewBox="0 0 16 16"
                                    class="bi bi-chat-dots"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
                                    />
                                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                            </div>
                        </div>
                        <div class="first-item">
                            <div class="item-description">
                                <h4>{howtoText}</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut
                                </p>
                            </div>
                            <div class="item-icon1">
                                <svg
                                    width="2.5rem"
                                    height="2.5rem"
                                    viewBox="0 0 16 16"
                                    class="bi bi-telephone-outbound"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>


                    <div class="col span_1_of_3 second-col">
                        <img class="big_image" src={howtoImage} alt="" />
                    </div>

                    <div class="col span_1_of_3 third-col">
                        <div class="last-item">
                            <div class="item-icon2">
                                <svg
                                    width="2.5rem"
                                    height="2.5rem"
                                    viewBox="0 0 16 16"
                                    class="bi bi-camera"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                                    />
                                    <path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                </svg>
                            </div>
                            <div class="item-description">
                                <h4>{howtoText}</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut
                                </p>
                            </div>
                        </div>
                        <div class="last-item">
                            <div class="item-icon2">
                                <svg
                                    width="2.5rem"
                                    height="2.5rem"
                                    viewBox="0 0 16 16"
                                    class="bi bi-camera-video"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175l3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                                    />
                                </svg>
                            </div>
                            <div class="item-description">
                                <h4>{howtoText}</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='flex items-center content-center justify-center w-full '>
                <div className='flex flex-row items-center w-auto gap-3'>

                    <AppCard data={appData} />

                </div>
            </div>
        </>
    )
}

export default Carousel