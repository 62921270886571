import React from 'react'

export default function KJVBiblePrivacyPolicy() {
    return (
        <div>
            <div class="bg-gray-100">
                <div class="container mx-auto px-4 py-8">
                    <h1 class="text-3xl font-bold mb-4">Privacy Policy for KJV Bible</h1>

                    <p class="mb-4">
                        <b>KJV Bible</b> is developed by <b>Fyn Systems (Yohannes Ejigu) </b> as an ad-supported, free app. This service is provided by Fyn Systems at no cost and is intended for use as is. This Privacy Policy explains how the app handles user data and complies with relevant policies and regulations.
                    </p>

                    <h2 class="text-2xl font-bold mb-2">Information Collection and Use</h2>

                    <ul class="list-disc list-inside mb-4">
                        <li><b>No Personal Data Collection</b></li>
                        <p class="mx-4">We do not collect, store, or transfer any user information. Your privacy is important to us, and we are committed to securing the data within our app.</p>
                        <li><b>Third-Party Services</b></li>
                        <p class="mx-4">
                            These third-party services may collect certain data for their functionality and analytics. You can refer to their privacy policies for more details
                            <p class='mx-8'>
                                <b>• Google Analytics: </b> To gather anonymous data on app usage to improve user experience.
                                <br />
                                <b>• AdMob: </b> To display personalized advertisements based on user activity.
                            </p>
                        </p>

                        <li><b>Crash Logs and Error Reports</b></li>
                        <p class="mx-4">
                            In case of app crashes or errors, we may request your consent to collect crash data. This data may include device-specific information, such as:
                            •	Device name
                            •	Operating system version
                            •	App configuration details at the time of the crash
                            •	Time and date of usage
                        </p>
                        <li>
                            This data is solely used to diagnose and resolve technical issues and improve app stability.
                        </li>

                        <li><b>App Permissions</b></li>
                        <p class="mx-4">
                            The app may request the following permissions:
                            <p class="mx-8">
                                <b> •	External Storage Access  </b> To store or retrieve Bible data for smoother usage.
                                <br />
                                <b> •	BOOT_COMPLETE  </b>
                                To ensure the app functions properly after device restarts. These permissions are used only to enhance the user experience and are not used for any data collection purposes.
                            </p>
                        </p>
                        <li>
                            These permissions are used strictly to enhance user experience and are not used for data collection purposes.
                        </li>
                    </ul>

                    <h2 class="text-2xl font-bold mb-2">Children's Privacy</h2>

                    <p class="mx-4">
                        Our services are not intended for anyone under the age of 13. We do not knowingly collect any personal information from children. If you believe that we have mistakenly collected such data, please contact us immediately, and we will promptly delete it.
                    </p>
                    <h2 class="text-2xl font-bold mb-2">Data Security</h2>

                    <p class="mx-4 mb-4">
                        We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
                        disclosure,
                        we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the
                        information we collect online.
                    </p>


                    <h2 class="text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>

                    <p class="mb-4 mx-4">
                        We may update this Privacy Policy from time to time. Changes will be posted on this page, and users are advised to review it periodically. The new policy will take effect immediately upon posting. <a href='https://fynsystem.com/fyn_privacy' className='font-bold text-blue-600'>Online Version</a>
                    </p>

                    <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

                    <p class="mb-4 mx-4">
                        If you have any questions or suggestions regarding this Privacy Policy, please feel free to contact us at:
                        <br />

                        <b class='mx-4'><a href='mailto:fynnsystems@gmail.com' className='text-blue-600'>fynnsystems@gmail.com</a></b>
                    </p>


                </div>
            </div>
        </div>
    )
}

