import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'

export default function AppCard({ data }) {
    const scrollContainerRef = useRef(null);
    const cardWidth = 200; // width of each card
    const visibleCards = 3; // number of visible cards

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -(cardWidth * visibleCards), behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: cardWidth * visibleCards, behavior: 'smooth' });
        }
    };
    return (
        <div className="flex items-center gap-5 my-10">
            <button onClick={scrollLeft}
                className=" top-[50%] ml-5 z-[1] left-0 flex items-center group justify-center w-12 h-12 duration-500 border border-[#d90647] border-solid rounded-full  hover:bg-[#d90647]">
                <svg
                    className="w-6 h-6 text-[#d90647] group-hover:text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M20.9999 12L4.99992 12M9.99992 6L4.70703 11.2929C4.3737 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.3737 12.3738 4.70703 12.7071L9.99992 18"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

            <div
                ref={scrollContainerRef}
                className="overflow-x-auto sm:w-[850px] w-[240px] flex gap-3"
                style={{ scrollBehavior: 'smooth' }}
            >
                {data.map((item, index) => (
                    <div key={index} className="w-[200px] flex-shrink-0" onClick={item.action}>
                        <div className="inline-block px-10 transition-all duration-500 bg-red border border-gray-300 border-solid w-[200px] h-[200px]  rounded-2xl hover:border-[#d90647] p-9">
                            <div className="flex flex-col items-center justify-center gap-3">
                                <img
                                    className="w-[70px] h-[70px]"
                                    src={item.logo}
                                    alt="avatar"
                                />
                                <div className="flex flex-col items-center">
                                    <h5 className="font-bold text-center text-gray-900 transition-all duration-500">
                                        {item.appName}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button onClick={scrollRight}
                className=" right-0 mr-5 z-[1] top-[50%] flex items-center justify-center w-12 h-12 transition-all duration-500 border border-[#d90647] border-solid rounded-full   group hover:bg-[#d90647]"
            >
                <svg
                    className="w-6 h-6 text-[#d90647] group-hover:text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    )
}
