import React, { Fragment } from 'react'
import Navbar from '../components/nav/Navbar'
import Footer from '../components/nav/Footer'

export default function DefaultLayout() {
    return (
        //fragment is a normal wrapper for different components with in it.
        <Fragment>
            <div className='w-full overflow-hidden'>
                <Navbar />
                <div>

                </div>
                <Footer />
            </div>
        </Fragment>
    )
}
