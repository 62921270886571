import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import AmharicBiblePrivacyPolicy from './pages/AmharicBiblePrivacyPolicy';
import KJVBiblePrivacyPolicy from './pages/KJVBiblePrivacyPolicy';
import FynGeezPrivacyPolicy from './pages/FynGeezPrivacyPolicy';
import DefaultLayout from './layouts/DefaultLayout';
import AdvancedEnglishDictionary from './pages/AdvancedEnglishDictionary';
import EastonBibleDictionary from './pages/EastonBibleDictionary';
import AmharicDictionaryPrivacy from './pages/AmharicDictionaryPrivacy';

function App() {
  return (
    <div className="App">
      <nav>

      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/amharic_bible_privacy' element={<AmharicBiblePrivacyPolicy />} />
        <Route path='/kjv_bible_privacy' element={<KJVBiblePrivacyPolicy />} />
        <Route path='/fyn_privacy' element={<FynGeezPrivacyPolicy />} />
        <Route path='/advanced_english_privacy' element={<AdvancedEnglishDictionary />} />
        <Route path='/easton_bible_dictionary' element={<EastonBibleDictionary />} />
        <Route path='/amharic_dictionary' element={<AmharicDictionaryPrivacy />} />

        <Route path='' element={<DefaultLayout />}>

        </Route>


      </Routes>

    </div>
  );
}

export default App;
