import React from 'react'

export default function FynGeezPrivacyPolicy() {
  return (
    <div>

      <div class="bg-gray-100 h-full">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-3xl font-bold mb-4">Privacy Policy for FynGeez</h1>

          <p class="mb-4">
            <b>Fyn systems</b> built the <b> FynGeez keyboard App </b> as an ad-supported free app. This Service is provided by <b>Fyn Systems/Yohannes Ejigu</b> at no cost and is intended for use as is.
          </p>

          <h2 class="text-2xl font-bold mb-2">Information Collection and Use</h2>

          <ul class="list-disc list-inside mb-4">
            <li><b>No Personal Data Collection</b></li>
            <p class="mx-4"><b>We do not collect and/or transfer what you type using FynGeez Keyboard App.</b> Our primary goal is to protect and secure what you type using our keyboard App.</p>
            <li><b>Third-Party Services</b></li>
            <p class="mx-4">
              The app integrates third-party services like Google Analytics and AdMob(or other advertisement networks) to enhance functionality and deliver personalized advertisements. These services may collect anonymos data for analytics purposes, including app usage statistics.
              <p class='mx-8'>
                <b>• Google Analytics: </b> To gather anonymous data on app usage to improve user experience.
                <br />
                <b>• AdMob: </b> To display personalized advertisements based on user activity.
              </p>
            </p>
            <li><b>Crash Logs and Error Reports</b></li>
            <p class="mx-4">
              In case of app crashes or errors, we may request your consent to collect crash data. This data may include device-specific information, such as:
              <br />
              <p className='mx-4'>
                •	Device name<br />
                •	Operating system version<br />
                •	App configuration details at the time of the crash<br />
                •	Time and date of usage
              </p>
            </p>
            <li>
              This data is solely used to diagnose and resolve technical issues and improve app stability.
            </li>

            <li><b>App Permissions</b></li>
            <p class="mx-4">
              Advanced English Dictionary app requests the following permissions:
              <p class="mx-8">
                <b> • Read Your Contacts</b> - This permission allows the app to access contact names stored on your device. It is used to:
                <p className='mx-4'>
                  • Enhance word prediction and auto-suggestions when typing names.<br />
                </p>
                <b> • Storage Permissions </b>Read the contents of your shared storage: This allows the app to access data stored on your device, such as saved dictionary files or custom settings, to improve typing experience and loading speed.                <br />
                <b> • Prevent Phone from Sleeping </b> - This permission ensures that the app runs smoothly while in use. It prevents the device from entering sleep mode, ensuring an uninterrupted experience when using the app.<br />

                <b> • Full Network Access </b> - This permission allows the app to connect to the internet for features like:
                <br />
                <p className='mx-4'>
                  • Fetching updates for the dictionary or other relevant content.<br />
                  • Modify or delete content related to the app to manage data usage efficiently. <br />
                </p>
                <b> • View Network Connections </b> - This permission allows the app to check the status of your internet connection to determine when to download updates or show ads. It helps in managing network usage and optimizing the performance of the app.<br />
                <b> • Control Vibration </b> - The app uses this permission to enable haptic feedback (vibration) when typing. This feature enhances user experience by providing tactile feedback for key presses.<br />
                <b> • Run at Startup </b> - This permission allows the app to automatically activate the keyboard when your device restarts, ensuring it is immediately available for use without needing manual activation.<br />
              </p>
              These permissions are used strictly to enhance user experience and are not used for data collection purposes.
            </p>
          </ul>

          <h2 class="text-2xl font-bold mb-2">Children's Privacy</h2>

          <p class="mx-4">
            The FynGeez app is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If you believe that we have collected such data, please contact us immediately so we can remove it.
          </p>

          <h2 class="text-2xl font-bold mb-2">Data Security</h2>

          <p class="mx-4 mb-4">
            We take your privacy and data security seriously. However, please note that no method of data transmission or electronic storage is completely secure, and we cannot guarantee the absolute security of your data.


          </p>

          <h2 class="text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>

          <p class="mb-4 mx-4">
            We may update this Privacy Policy from time to time. Changes will be posted on this page, and users are advised to review it periodically. The new policy will take effect immediately upon posting. <a href='https://fynsystem.com/fyn_privacy' className='font-bold text-blue-600'>Online Version</a>
          </p>

          <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

          <p class="mb-4 mx-4">
            If you have any questions or suggestions regarding this Privacy Policy, please feel free to contact us at:
            <br />

            <b class='mx-4'><a href='mailto:fynnsystems@gmail.com' className='text-blue-600'>fynnsystems@gmail.com</a></b>
          </p>

        </div>
      </div>
    </div>
  )
}
