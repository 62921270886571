import React from 'react'

export default function AmharicDictionaryPrivacy() {
    return (
        <div className='text-black'>

            <div class="bg-gray-100 h-full">
                <div class="container mx-auto px-4 py-8">
                    <h1 class="text-3xl font-bold mb-4">Privacy Policy for Amharic Dictionary</h1>

                    <p class="mb-4">
                        <b>Advanced English Dictionary</b> is developed by <b>Fyn Systems</b> as an ad-supported free app. This Service is provided by <b>Fyn Systems/Yohannes Ejigu</b> at no cost and is intended for use as is. This Privacy Policy outlines how the app handles user data, the permissions it requests, and how those permissions are used.
                    </p>

                    <h2 class="text-2xl font-bold mb-2">Information Collection and Use</h2>

                    <ul class="list-disc list-inside mb-4">
                        <li><b>No Personal Data Collection</b></li>
                        <p className='mx-4'>We do not collect or transfer any personally identifiable information <b>(PII)</b> from users.</p>

                        <li><b>Third-Party Services</b></li>
                        <p class="mx-4">
                            The app integrates third-party services like Google Analytics and AdMob(or other advertisement networks) to enhance functionality and deliver personalized advertisements. These services may collect anonymos data for analytics purposes, including app usage statistics.
                            <p class='mx-8'>
                                <b>• Google Analytics: </b> To gather anonymous data on app usage to improve user experience.
                                <br />
                                <b>• AdMob: </b> To display personalized advertisements based on user activity.
                            </p>
                        </p>
                        <li><b>Crash Logs and Error Reports</b></li>
                        <p class="mx-4">
                            In case of app crashes or errors, we may request your consent to collect crash data. This data may include device-specific information, such as:
                            <br />
                            <p className='mx-4'>
                                •	Device name<br />
                                •	Operating system version<br />
                                •	App configuration details at the time of the crash<br />
                                •	Time and date of usage
                            </p>
                        </p>
                        <li>
                            This data is solely used to diagnose and resolve technical issues and improve app stability.
                        </li>

                        <li><b>App Permissions</b></li>
                        <p class="mx-4">
                            Advanced English Dictionary app requests the following permissions:
                            <p class="mx-8">
                                <b> • Full Network Access</b> - This permission allows the app to access the internet to:

                                <br />
                                <p className='mx-4'>
                                    • Fetch and display updated dictionary definitions.<br />
                                    • Load additional content that enhances the user experience.<br />
                                </p>
                                <b> • View Network Connection </b> - To provide word prediction and suggestions while typing. This data is used locally on your device and is not shared or transmitted over any network.<br />
                                <b> • Prevent Phone from Sleeping </b> - This permission ensures that the app runs smoothly while in use. It prevents the device from entering sleep mode, ensuring an uninterrupted experience when using the app.<br />
                                <b> • Read and Modify Shared Storage </b> - The app requests access to shared storage to:<br />
                                <p className='mx-4'>
                                    • Read the contents of your storage to retrieve previously saved dictionary data.<br />
                                    • Modify or delete content related to the app to manage data usage efficiently. <br />
                                </p>
                            </p>
                            These permissions are used strictly to enhance user experience and are not used for data collection purposes.
                        </p>
                    </ul>

                    <h2 class="text-2xl font-bold mb-2">Children's Privacy</h2>

                    <p class="mx-4">
                        This app does not specifically address anyone under the age of 13. We do not knowingly collect personal information from children. If you believe that your child has inadvertently shared such information with us, please contact us, and we will take the necessary steps to delete it.
                    </p>
                    <h2 class="text-2xl font-bold mb-2">Data Security</h2>

                    <p class="mx-4 mb-4">
                        We value your trust and take appropriate measures to secure any data involved in the operation of the app. However, please note that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </p>

                    <h2 class="text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>

                    <p class="mb-4 mx-4">
                        We may update this Privacy Policy from time to time. Changes will be posted on this page, and users are advised to review it periodically. The new policy will take effect immediately upon posting. <a href='https://fynsystem.com/advanced_english_privacy' className='font-bold text-blue-600'>Online Version</a>
                    </p>

                    <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

                    <p class="mb-4 mx-4">
                        If you have any questions or suggestions regarding this Privacy Policy, please feel free to contact us at:
                        <br />

                        <b class='mx-4'><a href='mailto:fynnsystems@gmail.com' className='text-blue-600'>fynnsystems@gmail.com</a></b>
                    </p>

                </div>
            </div>
        </div>
    )
}



