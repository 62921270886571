import React from 'react';
import YohannesTributes from "../../assets/images/yohannes-tributes.png";

export default function Tributes() {
    return (
        <>
            <section class="text-gray-600 body-font">
                <div class="container  py-24 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 sm:w-1/2 w-full  rounded-[20px]  sm:pr-10 overflow-hidden mt-6 sm:mt-0 p-3">
                        <img class="object-cover object-center w-full h-full rounded-[20px]" src={YohannesTributes} alt="stats" />

                    </div>
                    <div class="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-center justify-center w-full">
                        <div class="w-full sm:p-4  mb-6">
                            <h1 class=" font-bold text-center  text-3xl mb-2 text-gray-900"> 🌼 Tributes to Yohannes Ejigu 🌼 </h1>
                            <div class="leading-relaxed font-normal">
                                <b> Yohannes Ejigu </b>
                            </div>
                        </div>
                        <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                            <h2 class="title-font font-medium text-3xl text-gray-900 justify-center">5 <span className='font-normal text-1xl'>+</span> Million</h2>
                            <p class="leading-relaxed font-bold">Users</p>
                        </div>

                        <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                            <h2 class="  text-3xl text-gray-900 font-medium justify-center">8 <span className='font-normal text-1xl'>+</span> Million </h2>
                            <p class="leading-relaxed font-medium">Downloads</p>
                        </div>
                        <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                            <h2 class=" font-medium text-3xl text-gray-900 font-medium justify-center">40 <span className='font-normal text-1xl'>+</span></h2>
                            <p class="leading-relaxed font-medium">Products</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
