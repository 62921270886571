import React, { useState } from "react";
import MobileImageSrc from "../assets/images/2.png";
import FynLogo from "../assets/images/app_logo.png";
import AppStore from "../assets/svg/app_store.svg";
import AppLogo from "../assets/svg/Logo.svg";
import Reviews from "../assets/images/review.png";
import FynColorLogo from "../assets/svg/fyn-system-icon.svg";

import amharicDic from "../assets/logos/amahric-dic.png";
import bible from "../assets/logos/bible.png";
import easton from "../assets/logos/easton.png";
import kjv from "../assets/logos/kjv.png";
import fynGeeze from "../assets/logos/fyngeeze.png";
import tools from "../assets/logos/tools.png";
import advancedDic from "../assets/logos/adv.png";




import "./css/styles.css";
import "./css/grid.css";
import Carousel from "../components/Carousel/Carousel";
import Tributes from "./tributes/Tributes";
// import ""

export default function Home() {

  return (
    <>
      <header id="home">
        <nav>
          <div class="navbar-logo flex gap-1 justify-center content-center">
            <img src={AppLogo} className="w-[65px]" alt="app logo" />
            <a href="/" className="text-[2.7rem] logo">
              fyn systems
            </a>
          </div>
          <div id="navbar">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#tutorials">Tutorials</a>
              </li>
              <li>
                <a href="#testimonial">Testimonials</a>
              </li>
              <li>
                <a href="#howto">Apps</a>
              </li>
              <li>
                <a href="#download">Contact Us</a>
              </li>
            </ul>
          </div>
          <div id="mobileNav">
            <span onclick="openNav()">&#9776;</span>
          </div>
          <div id="myNav" class="mobileNavOverlay">
            <div class="overlay-content">
              <a href="/" class="close-btn" onclick="closeNav()">
                &times;
              </a>
              <a href="#home" onclick="closeNav()">
                Home
              </a>
              <a href="#tutorials" onclick="closeNav()">
                Tutorials
              </a>
              <a href="#testimonial" onclick="closeNav()">
                Testimonials
              </a>
              <a href="#howto" onclick="closeNav()">
                Apps
              </a>
              <a href="#download" onclick="closeNav()">
                Contact Us
              </a>
            </div>
          </div>
        </nav>
        <div id="hero">
          <div class="row">
            <div class="col span_1_of_2">
              <div class="hero-description">
                <h2>Ethiopia’s Most Loved Apps</h2>
                <p>
                  from <span class="bold-text">fyn systems</span> company.
                </p>

                <a href="#download" class="btn" >
                  <div className="flex justify-center gap-3"><img className="h-[30px]" src={FynColorLogo} alt="" /><span class="download-btn"> Download Now</span></div>
                </a>
              </div>
            </div>
            <div class="col span_1_of_2">
              <img class="hero-img" src={MobileImageSrc} alt="" />
            </div>
          </div>
        </div>
        <div class="shape-divider">
          <div class="custom-shape-divider-bottom-1603385849">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
        <button onclick="topFunction()" id="scrollUp" title="Go to top">
          <svg
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 16 16"
            class="bi bi-arrow-up"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
            />
          </svg>
        </button>
      </header>
      <section id="testimonial">
        <div class="row">
          <div class="col span_1_of_2">
            <div class="features-images">
              <img class="features_1" src={Reviews} alt="" />
            </div>
          </div>
          <div class="col span_1_of_2">
            <div class="section-description features-description col1">
              <h2 class="stylish_heading">
                What users say about our apps<span class="red_dot">?</span>
              </h2>
              <p class="little-description">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </div>
      </section>


      <Carousel />
      <Tributes />


      <section id="download" className="">
        <div class="row">
          <img class="app-logo" src={FynLogo} alt="" />
          <h2 class="stylish_heading">
            Download the app <span class="red_dot">.</span>
          </h2>
          <p class="">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et.
          </p>

          <a
            href="https://play.google.com/store/apps/dev?id=8686777883246727435"
            blank="#"
          >
            <img src={AppStore} className="w-[300px]" alt="" />
          </a>
        </div>
      </section>

      <footer class="text-gray-600 body-font">


        <div class="bg-gray-100">
          <div class="container px-5 py-2 mx-auto flex items-center sm:flex-row flex-col">
            <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <img className="h-[40px]" src={FynColorLogo} alt="" /><span class="ml-3 text-lg">Fyn systems</span>
            </a>
            <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2024 Fyn systems </p>
            <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a class="text-gray-500">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a class="ml-3 text-gray-500">
                <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                  <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
