import React from 'react'
import { Container, Form, InputGroup } from 'react-bootstrap'
import { Fragment } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
// import LogoH from "../../Images/logo.svg";
// import LogoWhite from "../../Images/ghhfgh.svg";

const Navbar = () => {

    let location = useLocation();
    const [ToogleMenuResponsive, setToogleMenuResponsive] = useState(false);
    const [navabarScroll, setnavabarScroll] = useState(location.pathname == "" ? true : false)

    const stickNavabr = () => {
        if (window.scrollY > 100) {
            setnavabarScroll(true)
        } else {
            location.pathname != "" ? setnavabarScroll(false) : setnavabarScroll(true)
        }
    }

    useEffect(() => {
        location.pathname != "" ? setnavabarScroll(false) : setnavabarScroll(true)
        window.addEventListener('scroll', stickNavabr);
    }, [location])
    return (
        <Fragment>

            <div className={"fixed h-full w-full bg-white z-[99] pt-[100px] menuMobile " + (ToogleMenuResponsive ? "active" : "")}>
                <Container className='h-full'>
                    <ul className='list-none p-0 m-0 flex items-center flex-wrap gap-4 text-[20px] w-full'>
                        <li className='w-full'>
                            <a href="/" className='font-medium text-black'>Home</a>
                        </li>
                        <li className='w-full'>
                            <NavLink to="/services" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium text-black'>Service</NavLink>
                        </li>
                        <li className='w-full'>
                            <NavLink to="/about" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium text-black'>About us</NavLink>
                        </li>
                        <li className='w-full'>
                            <NavLink to="/contact" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium text-black'>Contact Us</NavLink>
                        </li>
                        <li className='w-full'>
                            <NavLink to="/blog" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium text-black'>Blog</NavLink>
                        </li>

                    </ul>
                </Container>
            </div>

            <div className={'fixed py-4 w-full z-[999] left-0 top-0 ' + (navabarScroll && (ToogleMenuResponsive == false) ? "bg-Mblue " : "bg-white")}>
                <Container className='relative flex items-center'>


                    <NavLink to="/" className=''>
                        {/* {
                            <img src={navabarScroll ? { LogoH } : { LogoWhite }} alt="" className='w-40' />

                        } */}
                        {/* <img src={navabarScroll ? LogoH : LogoWhite} className='w-40' alt='Bilih Digital Logo' /> */}

                    </NavLink>


                    <ul className='absolute items-center hidden gap-4 p-0 m-0 font-medium list-none -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 lg:flex text__16'>
                        <li>
                            {/* <a alt="Bilih Digital Home" to='/' className={' ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Home</a> */}
                            <NavLink to="/" alt="Bilih Digital Home" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Home</NavLink>

                        </li>
                        <li>
                            <NavLink to="/services" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Service</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/portfolio" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Portfolio</NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/about" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>About us</NavLink>
                        </li>

                        <li>
                            <NavLink to="/contact" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Contact</NavLink>
                        </li>
                        <li>
                            <NavLink to="/blog" className={'font-medium ' + (navabarScroll && (ToogleMenuResponsive == false) ? "text-Mwhite" : "text-Mblack")}>Blog</NavLink>
                        </li>
                    </ul>





                    <div onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className={"relative px-1 py-1 barIcon w-[30px] h-[30px] cursor-pointer lg:hidden ml-auto " + (ToogleMenuResponsive ? "active" : "")}>
                        <div className={!navabarScroll ? "!bg-Mblack" : ToogleMenuResponsive ? "!bg-Mblack" : ""}></div>
                        <div className={!navabarScroll ? "!bg-Mblack" : ToogleMenuResponsive ? "!bg-Mblack" : ""}></div>
                        <div className={!navabarScroll ? "!bg-Mblack" : ToogleMenuResponsive ? "!bg-Mblack" : ""}></div>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default Navbar
